import React from 'react';

import '../../styles/common/rainbow-bar.scss';

export default (props) => {
  let modifiers = '';

  switch (props.width) {
    case 'thin':
      modifiers += ' thin';
      break;
    case 'wide':
      modifiers += ' wide';
      break;
    default:
  }

  return (
    <div className={`rainbow-bar ${modifiers}`}></div>
  );
};