import React from 'react';
import { Helmet } from 'react-helmet';

import Footer from './footer';
import Header from './header';
import RainbowBar from '../common/rainbow-bar';

import '../../styles/base-layout.scss';

const BaseLayout = (props) => (
  <>
    <Helmet
      defaultTitle="Cookbooking"
      titleTemplate={`%s – Cookbooking`}
    >
      <html lang="en" prefix="og: http://ogp.me/ns#" />
      <meta charset="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta http-equiv="X-UA-Compatible" content="ie=edge" />

      <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
      <link rel="manifest" href="/site.webmanifest" />

      <meta
        property="og:title"
        content="Cookbooking"
      />

      <meta
        property="og:description"
        content="Discover new cookbooks"
      />
      <meta
        property="og:type"
        content="website"
      />
      <meta
        property="og:url"
        content="https://cookbooking.club"
      />
      <meta
        property="og:site_name"
        content="Cookbooking"
      />
    </Helmet>

    <RainbowBar width={'wide'} />
    <Header homepage={props.homepage} />
    <RainbowBar width={'thin'} />
    {props.children}
    <Footer />
    <script src="https://tinylytics.app/embed/Styd3fgVuhV1wSdUUcrb.js" defer></script>
  </>
);


export default BaseLayout;
