import React from 'react';

import { Link } from 'gatsby';
import {
  connectHits,
  connectStateResults,
  Hits,
  Index,
} from 'react-instantsearch-dom';

const BookHit = ({ hit }) => {
  const title = (hit.subtitle && hit.subtitle.length > 0)
    ? (
      <>
        {hit.title}: <span className="subtitle">{hit.subtitle}</span>
      </>
    ) : hit.title;

  return (
    <li>
      <Link to={`/book/${hit.slug}`}>
        <span className="cover">
          <img src={hit.coverImage} alt={ `${hit.title} cover` } />
        </span>
        <span className="details">
          <span className="title">
            {title}
          </span>
          {hit.authors && hit.authors.length > 0 && (
            <span className="byline">
              By {hit.authors}
            </span>
          )}
        </span>
      </Link>
    </li>
  );
};

const HitsInIndex = ({ index }) => (
  <Index indexName={ index.name }>
    <Hits className="Hits" hitComponent={ BookHit } />
  </Index>
);

const OverlaySearchResult = ({ indices }) => {
  return (
    <ul className="search-overlay__results">
      {indices.map((index) => (
        <HitsInIndex index={index} key={index.name} />
      ))}
    </ul>
  )
};

export default OverlaySearchResult;
