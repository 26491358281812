import React from 'react';

import SearchOverlayControls from './search-overlay-controls';

import { CloseSvg } from '../common/svg';

import '../../styles/search/search-overlay.scss';

const SearchOverlay = (props) => {
  return (
    <div className={ `search-overlay ${(props.expanded) ? 'expanded' : ''}` }>
      <button
        className="search-close-button"
        onClick={props.closeOverlayClick}
      >
        { CloseSvg }
      </button>
      <div className="search-panel">
        <div className="search-bar">
          <p className="label">
            Search Cookbooking
          </p>
          <SearchOverlayControls
            searchOverlayFieldRef={props.searchOverlayFieldRef}
            expanded={ props.expanded }
          />
        </div>
      </div>
    </div>
  )
};

export default SearchOverlay;