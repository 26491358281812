import React from 'react';
import { Link } from 'gatsby';
import moment from 'moment';

import PageColumn from '../layout/page-column';
import RainbowBar from '../common/rainbow-bar';
import Section from '../layout/section';

import { CookbookingSvg } from '../common/svg';

import "../../styles/layouts/footer.scss";

export default (props) => (
  <>
  <RainbowBar width={'thin'} />
  <footer>
    <Section topSpacing="section" bottomSpacing="subsection" color="dark-blue">
      <PageColumn>
        <div className="footer-links">
          <div>
            <div className="footer-title">
              <Link to="/">{ CookbookingSvg }</Link>
            </div>
            <div className="major-links">
              <Link to="/books">Browse All Cookbooks</Link>
            </div>
            <div className="minor-links">
              <span className="label">Browse By</span>
              <Link to="/categories">Category</Link>
              <Link to="/authors">Author</Link>
              <Link to="/publishers">Publisher</Link>
            </div>
          </div>
        </div>
      </PageColumn>
    </Section>
    <Section bottomSpacing="section" color="dark-blue">
      <PageColumn>
        <div>
          &copy; { moment().format('Y') }
        </div>
      </PageColumn>
    </Section>
  </footer>
  </>
);
