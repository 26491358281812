import React, { useState } from 'react';

import algoliasearch from 'algoliasearch/lite';
import { InstantSearch } from 'react-instantsearch-dom';
import OverlaySearchBox from './overlay-search-box';
import OverlaySearchResult from './overlay-search-result';

const algoliaClient = algoliasearch(
  process.env.GATSBY_COOKBOOKS_ALGOLIA_APP_ID,
  process.env.GATSBY_COOKBOOKS_ALGOLIA_SEARCH_KEY,
);

const searchClient = {
  search(requests) {
    if (requests[0].params.query === "") {
      return [];
    }

    return algoliaClient.search(requests);
  }
};

const SearchOverlayControls = (props) => {
  const [query, setQuery] = useState('');

  return (
    <>
    <InstantSearch
      searchClient={ searchClient }
      indexName={ 'cookbooking-books' }
      onSearchStateChange={ ({ query }) => setQuery( query ) }
    >
      <OverlaySearchBox
        searchOverlayFieldRef={ props.searchOverlayFieldRef }
      />
      <div className="search-results">
        {(query && query.length > 0) && (
          <OverlaySearchResult
            show={ query && query.length > 0 }
            indices={[{ name: 'cookbooking-books', title: 'cookbooking-books' }]}
          />
        )}
      </div>
    </InstantSearch>
    </>
  );
};

export default SearchOverlayControls;
