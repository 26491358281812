import React from 'react';

const spacingClasses = (props) => {
  let classes = 'section';

  switch (props.color) {
    case 'dark-blue':
      classes += ' dark-blue-bar';
      break;
    case 'yellow':
      classes += ' yellow-bar';
      break;
    case 'light-gray':
      classes += ' light-gray-bar';
      break;
    default:
      break;
  }

  switch (props.topSpacing) {
    case 'section':
      classes += ' section-spacing-top';
      break;
    case 'subsection':
      classes += ' subsection-spacing-top';
      break;
    default:
      break;
  }

  switch (props.bottomSpacing) {
    case 'section':
      classes += ' section-spacing-bottom';
      break;
    case 'subsection':
      classes += ' subsection-spacing-bottom';
      break;
    default:
      break;
  }

  return classes;
};

export default (props) => (
  <div
    className={ spacingClasses(props) }
  >
    { props.children }
  </div>
);