import React from 'react';
import { connectSearchBox } from 'react-instantsearch-dom';

function debounce(func, timeout = 300) {
  let timer;
  
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => { func.apply(this, args); }, timeout);
  }
}

const OverlaySearchBox = connectSearchBox(
  ({ refine, currentRefinement, className, onFocus, searchOverlayFieldRef }) => {
    const updateRefinement = debounce((query) => refine(query));
    
    return (
      <form action="/search" method="get" className={ className }>
        <input
          className="SearchInput"
          type="text"
          placeholder="Search"
          aria-label="Search"
          name="q"
          onChange={(e) => updateRefinement(e.target.value) }
          onFocus={onFocus}
          ref={searchOverlayFieldRef}
        />
        <input
          type="submit"
          value="View All Results"
        />
      </form>
    );
  }
);

export default OverlaySearchBox;