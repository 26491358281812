import React, { useState } from 'react';

import { Link } from 'gatsby';

import SearchOverlay from '../search/search-overlay';

import { CookbookingSvg, SearchSvg } from '../common/svg';

import "../../styles/layouts/header.scss";

const Header = (props) => {
  const [searchExpanded, setSearchExpanded] = useState(false);
  
  return (
    <>
    <header>
      <div className="page-width">
        <div className="contents">
          <div className="page-title">
            {props.homepage ? (
              <h1><Link to="/">{ CookbookingSvg }</Link></h1>
            ) : (
              <Link to='/'>{ CookbookingSvg }</Link>
            )}
          </div>
          <div className="search-button">
            <button
              onClick={ (e) => setSearchExpanded(true) }
            >
              { SearchSvg }
            </button>
          </div>
        </div>
      </div>
      <div className="color-flag"></div>
    </header>
    <SearchOverlay
      expanded={ searchExpanded }
      closeOverlayClick={ (e) => setSearchExpanded(false) }
    />
    </>
  );
};

export default Header;